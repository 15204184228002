<template>
    <div style="height: 100%">
        <div class="d-flex grey lighten-2 align-center px-2" style="position: fixed; top: 48px; left: 64px; height: 32px; width: calc(100% - 64px); z-index: 3">
            {{ longId }}
            <v-spacer />
            <v-btn @click="previewDialogLocal = false" icon><v-icon>highlight_off</v-icon></v-btn>
        </div>
        <div style="margin-top: 32px; height: calc(100% - 32px)">
            <iframe :src="pdfUrl" style="width: 100%; height: 100%; border: 0px" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['longId', 'pdfUrl', 'previewDialog'],
    data() {
        return {
            previewDialogLocal: this.$props.previewDialog
        }
    },
    watch: {
        previewDialogLocal(to, from) {
            this.$emit('update:previewDialog', to)
        },
        previewDialog(to, from) {
            this.previewDialogLocal = to
        }
    }
}
</script>
